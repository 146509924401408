<template>
  <div
    id="sticky"
    class="hidden-xs position-sticky sticky-top px-0"
  >
    <div
      v-show="scrollMenu.btnLeft"
      class="scroller scroller-left"
      @click="scrollMenu.left += scrollMenu.visibleWidth; scrollMenuRefresh();"
    >
      <i class="fas fa-chevron-left"></i>
    </div>
    <div
      v-show="scrollMenu.btnRight"
      class="scroller scroller-right"
      @click="scrollMenu.left -= scrollMenu.visibleWidth; scrollMenuRefresh();"
    >
      <i class="fas fa-chevron-right"></i>
    </div>
    <b-navbar
      v-b-scrollspy="100"
      class="nav-wrapper mx-0"
    >
      <b-nav
        pills
        :style="`left: ${scrollMenu.left}px`"
      >
        <b-nav-item
          v-for="category in Object.keys($t('dio.categories'))"
          :key="category"
          :to="`#${category}`"
        >
          <span v-html="$t(`dio.categories.${category}`)"></span>
        </b-nav-item>
      </b-nav>
    </b-navbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollMenu: {
        left: 0,
        listWidth: 0,
        visibleWidth: 0,
        btnLeft: false,
        btnRight: true,
      },
    };
  },

  mounted() {
    this.scrollMenuRefresh();
    window.onresize = () => {
      this.scrollMenuRefresh();
    };
  },

  methods: {
    scrollMenuRefresh() {
      this.scrollMenu.listWidth = document.querySelector('#sticky .nav').offsetWidth;
      this.scrollMenu.visibleWidth = document.querySelector('#sticky .nav-wrapper').offsetWidth;
      if (this.scrollMenu.visibleWidth - this.scrollMenu.left < this.scrollMenu.listWidth) {
        this.scrollMenu.btnRight = true;
      } else {
        this.scrollMenu.btnRight = false;
        this.scrollMenu.left = this.scrollMenu.visibleWidth - this.scrollMenu.listWidth;
      }
      if (this.scrollMenu.left < 0) {
        this.scrollMenu.btnLeft = true;
      } else {
        this.scrollMenu.btnLeft = false;
        this.scrollMenu.left = 0;
      }
    },
  },
};
</script>

<template>
  <section
    id="services"
    class="row mb-5"
  >
    <b-container class="ombre">
      <div class="white-space"></div>
      <services-menu />
      <div class="white-space"></div>

      <div
        v-for="category in Object.keys($t('dio.categories'))"
        :id="category"
        :key="category"
        tag="section"
        class="row py-4"
      >
        <b-col cols="12">
          <h2
            class="text-center h1 mb-4 mt-2"
            v-html="$t(`dio.categories.${category}`)"
          ></h2>
        </b-col>

        <service
          v-for="key in Object.keys($t('dio')).filter(k => k !== 'categories')"
          :id="key"
          :key="key"
          :category="category"
        />
      </div>

      <b-row id="deframa">
        <b-col
          lg="9"
        >
          <div v-html="$t('home.alt')"></div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import ServicesMenu from './ServicesMenu.vue';
import Service from './Service.vue';

export default {
  components: {
    ServicesMenu,
    Service,
  },
};
</script>

<style lang="scss">
#services {
  padding-bottom: 3rem;

  .card {
    border: none;
    background: #fff;
    border-radius: .5rem;
    padding: 0;

    .card-body {
      padding: 1rem;
    }

    .col-lg-5 {
      padding: .5rem;
      background: linear-gradient(170deg, var(--f-o3), var(--f-o4));

      border-radius: .5rem .5rem 0 0;
      @media (min-width:992px) {
        border-radius: .5rem 0 0 .5rem;
      }
    }

    .btn-sm {
      background-image: none;
    }
  }

  .ombre {
    background: var(--f-g2);
    border-radius: 1.5rem;
    box-shadow: none;
    padding: 1.5rem 0 0;

    > .row {
      margin: 0 1rem;
    }
  }

  #deframa.row {
    margin: 0;
    padding: 1rem;
    background: var(--f-g3);
    border-radius: 0 0 1.5rem 1.5rem;
  }

  .ombre:first-of-type {
    padding-top: 0;
  }

  .white-space {
    background: #fff;
    border-radius: 0;
    display:block;
    height: 1rem;
    &:first-of-type {
      border-radius: 1.5rem 1.5rem 0 0;
      height: 1.5rem;
    }
  }

  #sticky {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;

    .nav-wrapper {
      background: #fff;
    }

    .scroller {
      background: transparent;
    }

    .nav li:first-child.active {
      display: table-cell;
    }

    .active {
      background: var(--f-g3);
    }
  }
}
</style>

<template>
  <b-col
    v-if="$t('fight').indexOf(id) > -1
      && (category === 'all'
      || category === $t(`services.${id}.c2`))"
    class="text-center mb-4"
    md="3"
    sm="6"
    tag="article"
  >
    <h3>
      <i :class="`${$t(`icon.${fid}`)} fa-lg fc-g6 d-block text-center mb-3`"></i>
      <span v-html="$t(`color.${fid}`)"></span>
    </h3>
    <p
      class="desc"
      v-html="$t(`services.${id}.tDesc`)"
    ></p>
    <p>
      <img
        alt=""
        class="img-fluid"
        :src="`${$t('baseurl')}img/screens/${fid}.png`"
      />
    </p>
    <div>
      <a
        class="btn btn-lg float-left text-uppercase"
        :href="$t(`link.${fid}`)"
        v-html="$t('txt.use')"
      ></a>

      <b-dd
        class="float-right"
        dropup
        no-caret
        right
        variant="lg fc-g6"
      >
        <template #button-content>
          <i class="far fa-circle-question fa-lg"></i>
          <span
            class="sr-only"
            v-html="$t('txt.plus')"
          ></span>
        </template>
        <b-dd-item
          href="#"
          @click.prevent.stop="modal.open = true; modal.key = id; modal.fid = fid;"
        >
          <span v-html="$t('txt.more')"></span>
        </b-dd-item>
        <b-dd-item
          v-if="$te(`doc.${fid}[${$t('lang') !== 'fr' | 0}]`)"
          :href="`${$t('link.docs')}/${$t(`doc.${fid}[${$t('lang') !== 'fr' | 0}]`)}`"
        >
          <span v-html="$t('txt.docs')"></span>
        </b-dd-item>
      </b-dd>
    </div>

    <!-- <b-modal> --------------------------------------------- -->
    <b-modal
      id="f-di-modal"
      v-model="modal.open"
      footer-class="d-block"
      :modal-class="`m-${modal.key}`"
      ok-only
      :ok-title="$t('txt.close')"
      ok-variant="light"
      title-tag="h4"
      size="lg"
    >
      <!-- modal-header -->
      <template #modal-title>
        <img
          alt=""
          class="float-left"
          :src="`https://framasoft.org/nav/img/icons/ati/sites/${modal.fid}.png`"
        />
        <span
          class="frama"
          v-html="$t(`color.${modal.fid}`)"
        ></span>
        <br />
        <span
          class="desc"
          v-html="$t(`services.${modal.key}.lDesc`)"
        ></span>
      </template>

      <web-screen :img="`${$t('baseurl')}img/screens/${modal.fid}-full.png`" />

      <!-- desc -->
      <p
        v-html="$t(`services.${modal.key}.mBody.desc`)
          .replace(/@framaservice/g, $t(`services.${modal.key}.F`))"
      ></p>

      <!-- video / desc -->
      <p
        v-if="$te(`services.${modal.key}.mBody.more`)"
        v-html="$t(`services.${modal.key}.mBody.more`)
          .replace(/@framaservice/g, $t(`services.${modal.key}.F`))"
      ></p>

      <!-- features -->
      <div
        v-if="$te(`services.${modal.key}.mBody.feat`)"
      >
        <h5
          class="h3 violet"
          v-html="$t('txt.features')"
        ></h5>
        <ul v-if="Array.isArray($t(`services.${modal.key}.mBody.feat`))">
          <li
            v-for="item in $t(`services.${modal.key}.mBody.feat`)"
            :key="item"
            v-html="item.replace(/@framaservice/g, $t(`services.${modal.key}.F`))"
          ></li>
        </ul>
        <p
          v-else
          v-html="$t(`services.${modal.key}.mBody.feat`)
            .replace(/@framaservice/g, $t(`services.${modal.key}.F`))"
        ></p>
      </div>

      <!-- modal-footer -->
      <template #modal-footer="{}">
        <b-row>
          <!-- tags buttons -->
          <ul class="list-inline col-md-6 text-left">
            <li
              v-for="tag in $t(`services.${modal.key}.tags`)
                .split(', ').filter(e => e !== '' && e !== ' ')"
              :key="tag"
              class="list-inline-item"
            >
              <a
                class="btn btn-sm btn-light border py-0"
                :href="`#tag-${tag.replace(' ', '-').toLowerCase()}`"
                @click="modal.open = false; goTo('results')"
                v-html="tag.replace(/ /, '').toLowerCase()"
              ></a>
            </li>
          </ul>
          <!-- docs / install buttons -->
          <div class="col-md-6 text-right">
            <a
              v-if="$te(`doc.${modal.fid}[${$t('lang') !== 'fr' | 0}]`)"
              class="btn btn-lg btn-link text-uppercase"
              :href="`${$t('link.docs')}/${$t(`doc.${modal.fid}[${$t('lang') !== 'fr' | 0}]`)}`"
              v-html="$t('txt.docs')"
            ></a>

            <a
              class="btn btn-lg btn-link text-uppercase"
              :href="$t(`link.${modal.fid}`)"
              v-html="$t('txt.use')"
            ></a>
          </div>
        </b-row>
      </template>
    </b-modal>
    <!-- </b-modal> -------------------------------------------- -->
  </b-col>
</template>

<script>
import WebScreen from '../../../commons/components/WebScreen.vue';

export default {
  components: {
    WebScreen,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      fid: this.$t(`services.${this.id}.id`),
      modal: {
        open: false,
        key: 'bitly',
        fid: 'link',
      },
    };
  },

  methods: {
    goTo(target) {
      setTimeout(() => { document.getElementById(target).scrollIntoView({ behavior: 'smooth' }); }, 400);
    },
  },
};
</script>

<template>
  <section
    id="search"
    :class="search.length >= 3 ? 'fb-g2' : ''"
  >
    <b-container class="py-4 px-0">
      <b-row
        :class="`position-sticky sticky-top mx-0 ${search.length >= 3 ? 'fb-g2' : ''}`"
      >
        <b-col
          cols="10"
          sm="8"
          lg="6"
          class="mx-auto my-0 rounded fb-g2 py-3 px-2"
        >
          <b-row class="mx-0">
            <b-col
              class="text-right fc-g7 pt-1 px-1"
              cols="1"
              for="services-search"
              tag="label"
            >
              <i class="fas fa-magnifying-glass fa-lg"></i>
              <span
                class="sr-only"
                v-html="$t('txt.search')"
              ></span>
            </b-col>
            <b-col
              class="px-1"
              cols="11"
            >
              <b-form-input
                id="services-search"
                v-model="search"
                class="border-0"
                :placeholder="$t('home.search')"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <div
        v-show="search.length >= 3"
        id="results"
        class="mx-0"
      >
        <h2
          class="text-center h1 mt-4 mb-3"
          v-html="$t('txt.results')"
        ></h2>

        <b-card
          v-for="id in services"
          :id="`q-${id}`"
          :key="id"
          :class="`h-100 fd-g3 mb-4 ${results.includes(id) ? '' : 'd-none'} `"
          no-body
        >
          <b-row
            class="h-100"
            no-gutters
          >
            <b-col
              lg="6"
              class="p-4 pr-lg-0"
            >
              <a
                aria-hidden="true"
                :href="$t(`link.${id}`)"
                rel="nofollow"
              >
                <web-screen :img="`${$t('baseurl')}img/screens/${id}-full.png`" />
              </a>
            </b-col>
            <b-col lg="6">
              <b-card-body class="h-100 d-flex flex-column p-0 px-4 pb-4 pt-lg-4">
                <h3 class="card-title h4">
                  <a
                    :href="$t(`link.${id}`)"
                    rel="nofollow"
                  >
                    <i :class="`${$t(`icon.${id}`)} fa-lg fc-g6 fa-pull-right`"></i>
                    <div class="d-flex align-items-center text-left">
                      <span>
                        <span v-html="$t(`color.${id}`)"></span>
                      </span>
                    </div>
                  </a>
                </h3>
                <b-card-text v-html="$t(`dio.${id}.tDesc`)" />

                <b-card-text
                  v-if="$te(`dio.${id}.gafam`)"
                  text-tag="div"
                >
                  <p
                    class="m-0"
                    v-html="$t('txt.altTo')"
                  ></p>
                  <ul class="list-unstyled d-flex flex-wrap mb-4">
                    <li
                      v-for="gafam in $t(`dio.${id}.gafam`)"
                      :key="gafam"
                      class="col-6 py-2 fb-o1"
                    >
                      <img
                        v-if="Object.keys(img.gafam).indexOf($t(gafam, '-ktL@')) > -1"
                        alt=""
                        :src="img.gafam[$t(gafam, '-ktL@')]"
                      />
                      <span v-html="gafam.replace(/@:[.a-z]+ /g, '')"></span>
                    </li>
                  </ul>
                </b-card-text>

                <b-card-text class="mt-auto mb-0">
                  <a
                    v-if="$te(`doc.${id}[${$t('lang') !== 'fr' | 0}]`)"
                    :class="`btn btn-sm fb-d1 fd-g3
                      d-inline-flex align-items-center`"
                    :href="`${$t('link.docs')}/${$t(`doc.${id}[${$t('lang') !== 'fr' | 0}]`)}`"
                  >
                    <i class="fas fa-lightbulb fa-pull-left fc-g6"></i>
                    <span v-html="$t('txt.docs')"></span>
                  </a>

                  <a
                    :class="`btn btn-sm btn-outline-warning
                      d-inline-flex align-items-center float-right`"
                    :href="$t(`link.${id}`)"
                  >
                    <span v-html="$t('txt.use')"></span>
                    <span
                      class="mx-1"
                      v-html="$t(`txt.${id}`)"
                    ></span>
                    <i class="fas fa-chevron-right fa-pull-left"></i>
                  </a>
                </b-card-text>
                <b-card-text>
                  <b-alert
                    v-if="/(calc|date|pad|talk)/.test(id)"
                    show
                    variant=" fb-o2 mt-3 mb-0 p-2 text-left d-flex align-items-center border-0"
                  >
                    <a
                      :href="$t('link.entraide')"
                      aria-hidden="true"
                    >
                      <img
                        :src="`${$t('baseurl')}img/icons/chatons/${id}.svg`"
                        alt=""
                        class="rounded-circle ml-1 mr-3"
                        style="width: 70px; height: 70px; min-width: 70px;"
                      />
                    </a>
                    <p
                      class="mb-0 py-2"
                      v-html="$t('home.entraide')"
                    ></p>
                  </b-alert>
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          id="noresults"
          :class="`h-100 fd-g2 mb-4 ${results.length > 0 ? 'd-none' : ''} `"
          no-body
        >
          <b-row
            class="justify-content-center"
            no-gutters
          >
            <b-col
              lg="8"
              class="p-4"
            >
              <p v-html="$t('home.noresults')"></p>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-container>
  </section>
</template>

<script>
import WebScreen from '../../../commons/components/WebScreen.vue';
import img from '../../../public/img/img.json';

export default {
  components: {
    WebScreen,
  },

  data() {
    return {
      img: !document.getElementById('app').hasAttribute('data-server-rendered')
        ? img
        : { gafam: {}, leds: {} },
      results: [],
      services: Object.keys(this.$t('dio')).filter(k => !/(categories|piaf)/.test(k)),
      search: '',
    };
  },

  watch: {
    search(newValue) {
      if (newValue.length >= 3) {
        this.searchUpdate();
      }
    },
  },

  methods: {
    searchUpdate() {
      const words = this.$t(this.search.replace(/\+ ?/, '_'), '-kLl@').split('_');
      const reg = new RegExp(`(${words.join('|')})`, 'g');
      this.results = [];

      this.services.forEach((id) => {
        const content = [
          this.$t(`txt.${id}`), /* Framastuff */
          this.$t(`dio.${id}.gafam`).join(' '), /* Alternative to */
          this.$t(`dio.${id}.tDesc`), /* Long description */
        ].join(' ');
        if (reg.test(this.$t(content, '-kLl@'))) {
          this.results.push(id);
        }
      });
    },
  },

};
</script>

<style lang="scss">
#search {
  .btn-sm {
    background-image: none;
  }

  .web-browser {
    margin: 0;
  }

  .alert {
    img {
      border: 3px solid transparent;
      min-width: 70px;
      width: 70px;
      height: 70px;
    }
  }
}

#q-calc .alert img { border-color: #677b3b; }
#q-date .alert img { border-color: #ecba3a;}
#q-pad .alert img { border-color: #4a8deb; }
#q-talk .alert img { border-color: #645e5c; }
</style>

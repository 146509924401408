<template>
  <main>
    <landing />

    <why />

    <search />
    <services />

    <f-news v-if="/^fr/.test(this.$t('lang'))" />

    <who />

    <sfs />

    <section
      id="signature"
      class="pb-1"
    >
      <b-container>
        <div class="mx-n4">
          <signature />
        </div>
      </b-container>
    </section>
  </main>
</template>

<script>
import Landing from './home/Landing.vue';
import Why from './home/Why.vue';
import Search from './home/Search.vue';
import Services from './home/Services.vue';
import FNews from '../components/FNews.vue';
import Who from '../../commons/components/hos/Who.vue';
import Sfs from '../../commons/components/sfs/Main.vue';
import Signature from '../components/Signature.vue';

export default {
  components: {
    Landing,
    Why,
    Search,
    Services,
    FNews,
    Who,
    Sfs,
    Signature,
  },

  data() {
    return {
      explain: false,
    };
  },
};
</script>

<style lang="scss">
html {
  --f-i9: #0b1c54;
  position: relative;
}

#home {
  background: linear-gradient(170deg,#5f2d76,#7dafb6);
  font-family: Roboto;
  position: relative;
  overflow-y: scroll;

  &::before {
    background:
      var(--f-f8)
      url('../../public/img/bg-sky.jpg')
      no-repeat
      center;
    background-size: cover;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  #app {
    position: relative;
    z-index: 1;

    .btn-outline-warning {
      font-weight: bold;
      border: none;
      background: #fff;

      &:hover, &:focus, &:active {
        background: var(--f-o7);
        color: #fff;
      }
    }
  }

  main > .row {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

#who, #f-sfs, #signature {
  background: var(--f-g3);
}

#search, #services {
  .orange, .rouge {
    color: var(--f-g7);
  }
  .violet, .fc-d9, h3 span > span {
    color: var(--f-g7);
    font-weight: normal;
  }
}

#q-listes, #s-listes { display: none; }

#home {
  #who, #support, #lastyear {
    h2 {
      background: var(--f-o6);
      border-radius: .5rem;
      color: #fff;
      font-family: 'Tovari Sans', sans-serif;
      font-size: 2.4rem;
      @media (min-width: 576px) {
        font-size: 3rem;
      }
      font-style: normal;
      line-height: 2.5rem;
      padding: 1.25rem 1.75rem .5rem;
      text-align: center;
      text-transform: uppercase;
      transform: rotate(-2deg);
      width: fit-content;
    }
  }

  #who {
    h2 {
      max-width: 600px;
      margin: -5rem auto 2rem 0;
      @media (min-width: 992px) {
        margin: -6rem auto 2rem 0;
      }
    }
  }

  #support {
    margin-top: 0;
    padding-bottom: 3rem;

    @media (min-width: 992px) { /* cat is hidden by the form below 992px */
      background:
        transparent
        url('../../public/img/gattino-galileo.png')
        no-repeat
        10% calc(100% + 40px);
    }

    h2 {
      margin: -1rem auto 2rem 0;
      @media (min-width: 992px) {
        margin: -2rem auto 2rem 0;
      }
    }

    h3 {
      color: var(--f-i9);
    }
  }

  #signature {
    @media (min-width: 992px) { /* cat is hidden by the form below 992px */
      background:
        var(--f-g3)
        url('../../public/img/gattino-galileo.png')
        no-repeat
        10% calc(100% + 40px);
    }
    padding: 1rem 0;
  }

  #actions {
    h1 {
      background: var(--f-o6);
      border-radius: .5rem;
      color: #fff;
      font-family: 'Tovari Sans', sans-serif;
      font-size: 2.4rem;
      @media (min-width: 576px) {
        font-size: 3rem;
      }
      font-style: normal;
      line-height: 2.5rem;
      padding: 1.25rem 1.75rem .5rem;
      text-align: center;
      text-transform: uppercase;
      transform: rotate(-2deg);
      width: fit-content;
      margin: -5rem auto 3rem 0 !important;
    }

    h2 {
      font-family: 'Tovari Sans', sans-serif;
      font-size: 2.4rem;
      @media (min-width: 576px) {
        font-size: 3rem;
      }
      line-height: 2.5rem;
      margin-bottom: 1rem;
      text-transform: none;
      transform: none;
    }
  }

  #lastyear {
    h2 {
      margin: -5rem auto 4rem 0;
    }

    h3 {
      color: var(--f-i9);
      font-family: 'Tovari Sans', sans-serif;
      font-size: 2.4rem;
      @media (min-width: 576px) {
        font-size: 3rem;
      }
      line-height: 2.5rem;
      margin-bottom: 1rem;
    }
  }
}
</style>

<template>
  <div
    id="sticky"
    class="hidden-xs position-sticky sticky-top px-0"
  >
    <div
      v-show="scrollMenu.btnLeft"
      class="scroller scroller-left"
      @click="scrollMenu.left += scrollMenu.visibleWidth; scrollMenuRefresh();"
    >
      <i class="fas fa-chevron-left"></i>
    </div>
    <div
      v-show="scrollMenu.btnRight"
      class="scroller scroller-right"
      @click="scrollMenu.left -= scrollMenu.visibleWidth; scrollMenuRefresh();"
    >
      <i class="fas fa-chevron-right"></i>
    </div>
    <div class="nav-wrapper mx-0">
      <b-nav
        v-b-scrollspy:alternatives
        pills
        :style="`left: ${scrollMenu.left}px`"
      >
        <b-nav-item
          href="#bloc-carte"
          :title="$t('txt.backToMap')"
        >
          <img
            :alt="$t('txt.backToMap')"
            :src="`${$t('baseurl')}img/${$t('lang')}/carte_petite.png`"
            style="width: 40px; height: 40px;"
          />
        </b-nav-item>
        <b-nav-item
          v-for="(icon, cat) in $t('cat1.icons')"
          :key="cat"
          :href="`#${cat}`"
          :title="$t(`cat1.${cat}`)"
        >
          <i :class="icon"></i>
          <span v-html="$t(`cat1.${cat}`)"></span>
        </b-nav-item>
      </b-nav>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollMenu: {
        left: 0,
        listWidth: 0,
        visibleWidth: 0,
        btnLeft: false,
        btnRight: true,
      },
    };
  },

  created() {
    this.$root.$on('scrollspy::activate', this.onActivate);
  },

  mounted() {
    this.scrollMenuRefresh();
    window.onresize = () => {
      this.scrollMenuRefresh();
    };
  },

  methods: {
    scrollMenuRefresh() {
      this.scrollMenu.listWidth = document.querySelector('#sticky .nav').offsetWidth;
      this.scrollMenu.visibleWidth = document.querySelector('#sticky .nav-wrapper').offsetWidth;
      if (this.scrollMenu.visibleWidth - this.scrollMenu.left < this.scrollMenu.listWidth) {
        this.scrollMenu.btnRight = true;
      } else {
        this.scrollMenu.btnRight = false;
        this.scrollMenu.left = this.scrollMenu.visibleWidth - this.scrollMenu.listWidth;
      }
      if (this.scrollMenu.left < 0) {
        this.scrollMenu.btnLeft = true;
      } else {
        this.scrollMenu.btnLeft = false;
        this.scrollMenu.left = 0;
      }
    },

    onActivate(target) {
      console.log('Receved Event: scrollspy::activate for target ', target);  // eslint-disable-line
    },
  },
};
</script>

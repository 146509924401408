<template>
  <header id="header">
    <div class="float-right">
      <I18n />
    </div>
    <h1 v-html="$t('meta.DIo')"></h1>
    <p
      class="lead text-center"
      v-html="$t((this.$route.path.split('/')[2].replace('ernatives', '') || 'meta') + '.title')"
    ></p>
    <hr
      class="trait"
      role="presentation"
    />
  </header>
</template>

<script>
import I18n from '../../commons/components/I18n.vue';

export default {
  components: {
    I18n,
  },
};
</script>

<style lang="scss">
  #header {
    background: #fff;
    // border-bottom: 1px solid var(--f-g4);

    h1 {
      margin: 0;
      line-height: normal;
      a {
        text-decoration: none;
      }
    }

    .btn {
      border: transparent;
    }
  }
</style>

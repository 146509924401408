<template>
  <b-row id="bloc-carte">
    <b-col
      class="map"
      lg="8"
    >
      <h2
        class="h3"
        v-text="$t('map.title')"
      ></h2>
      <div id="map-container">
        <img
          id="carte"
          :alt="$t('map.alt')"
          :src="`data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAtoAAANSAQMAAABhtPt${
            ''}xAAAAA1BMVEUAAACnej3aAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAA${
            ''}DZQAAA2UARCw/3wAAAAHdElNRQfgCwQIJQGzxaatAAAAY0lEQVR42u3BMQEAAADCoPVPb${
            ''}QsvoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA${
            ''}AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADgYTTZAAHjzPeQAAAAAElFTkSuQmCC`"
          usemap="#cartemap"
        />
        <map
          id="cartemap"
          name="cartemap"
        >
          <!-- Village, Big camps, NSA-->
          <area
            v-for="(camp, key) in $t('camps')"
            :id="`a-${key}`"
            :key="key"
            :alt="$t(`camps.${key}.title`, '-t')"
            :class="key"
            :coords="camp.pos"
            :data-maphilight="JSON.stringify(mapHighlight[camp.highlight])"
            href="javascript:void(0);"
            shape="circle"
            tabindex="-1"
            :title="$t(`camps.${key}.title`, '-t')"
            @click="modal2.open = true; modal2.key = key;"
          />
          <!-- Little camps -->
          <area
            v-for="key in Object.keys($t('services'))
              .filter(key => /^[0-9]+,[0-9]+,[0-9]+$/.test($t(`services.${key}.pos`)))"
            :id="`a-${key}`"
            :key="key"
            :alt="$t(`services.${key}.gafam[0]`, '-t')"
            :coords="$t(`services.${key}.pos`)"
            :data-maphilight="JSON.stringify(mapHighlight.services)"
            href="javascript:void(0);"
            shape="circle"
            tabindex="-1"
            @click="modal.open = true; modal.key = key;"
          />
        </map>
        <video
          id="mapVideo"
          ref="mapVideo"
          autoplay
          loop
          muted
          :poster="`${$t('baseurl')}img/${$t('lang')}/Carte2016-romains.png`"
        >
          <source
            :src="`${$t('baseurl')}img/${$t('lang')}/Carte2016-animation.webm`"
            type="video/webm"
          />
          <source
            :src="`${$t('baseurl')}img/${$t('lang')}/Carte2016-animation.mp4`"
            type="video/mp4"
          />
          <img
            alt=""
            :src="`${$t('baseurl')}img/${$t('lang')}/Carte2016-romains.png`"
            style="width:100%;"
          />
        </video>
        <div class="play-pause">
          <button
            v-show="play"
            :title="$t('txt.pause')"
            @click="play=false; $refs.mapVideo.pause()"
          >
            <i class="fas fa-pause fa-fw fc-g6"></i>
            <span
              class="sr-only"
              v-html="$t('txt.pause')"
            ></span>
          </button>

          <button
            v-show="!play"
            :title="$t('txt.play')"
            @click="play=true; $refs.mapVideo.play()"
          >
            <i class="fas fa-play fa-fw fc-g6"></i>
            <span
              class="sr-only"
              v-html="$t('txt.play')"
            ></span>
          </button>
        </div>
      </div>
    </b-col>

    <b-col lg="4">
      <!-- Recherche -->
      <div class="row bg-light rounded mt-5 mb-4 pt-3 pb-2 mx-auto">
        <label
          class="col-sm-1 text-right py-1 px-2"
          for="tags-select"
        >
          <i class="fas fa-magnifying-glass fc-g6"></i>
          <span
            class="sr-only"
            v-html="$t('txt.searchByAlt')"
          ></span>
        </label>
        <div class="col-sm-11 px-2">
          <v-select
            id="tags-select"
            v-model="results"
            label="gafam"
            :options="tags($t('services'))"
            :placeholder="$t('txt.searchByAlt')"
            @input="modal.key = results.key;
                    modal.open = (results.key !== undefined && results.key !== '');"
          />
        </div>
      </div>

      <!-- Intro alternatives -->
      <p v-html="$t('alt.text1')"></p>
      <p v-html="$t('alt.text2')"></p>
      <p v-html="$t('alt.text3')"></p>
      <p
        id="network"
        aria-hidden="true"
        class="text-center fc-g6"
      >
        <i class="fas fa-house fa-fw"></i>
        →
        <i class="fas fa-cloud fa-fw"></i>
        →
        <i class="fas fa-server fa-fw"></i>
      </p>
      <p v-html="$t('alt.text4')"></p>
    </b-col>

    <!-- <b-modal> --------------------------------------------- -->
    <b-modal
      v-if="modal.key && modal.key !== 'gmail'"
      id="f-di-modal"
      v-model="modal.open"
      footer-class="d-block"
      :modal-class="`m-${modal.key}`"
      ok-only
      :ok-title="$t('txt.close')"
      ok-variant="light"
      title-tag="h4"
      size="lg"
    >
      <template #modal-title>
        <img
          alt=""
          class="float-left"
          :src="`https://framasoft.org/nav/img/icons/ati/sites/${$t(`services.${modal.key}.F`, '-tlL@~')}.png`"
        />
        <span
          class="frama"
          v-html="$t(`services.${modal.key}.F`)"
        ></span>
        <br />
        <span
          class="desc"
          v-html="$t(`services.${modal.key}.lDesc`)"
        ></span>
      </template>

      <!-- alternative to -->
      <div class="bg-light rounded p-4 mb-3">
        <p>
          <span v-html="$t('txt.asAltTo')"></span>
          <span
            aria-hidden="true"
            class="fc-g6"
            title="Google, Apple, Facebook, Amazon, Microsoft"
          >
            <i class="fab fa-google"></i>
            <i class="fab fa-apple"></i>
            <i class="fab fa-facebook-f"></i>
            <i class="fab fa-amazon"></i>
            <i class="fab fa-microsoft"></i>
          </span>
          <span class="sr-only">GAFAM</span>
          <span v-html="`${$t('txt.andCo')}${$t('txt.like')}`"></span>
        </p>
        <ul class="list-group mb-3">
          <li
            v-for="gafam in $t(`services.${modal.key}.gafam`)"
            :key="gafam"
            class="list-group-item"
          >
            <img
              v-if="Object.keys(img.gafam).indexOf($t(gafam, '-ktL@')) > -1"
              alt=""
              :src="img.gafam[$t(gafam, '-ktL@')]"
            />
            <span v-html="gafam.replace(/@:[.a-z]+ /g, '')"></span>
          </li>
        </ul>
        <p
          v-if="$t(`services.${modal.key}.FDate`).length === 4"
          class="m-0"
        >
          <span v-html="$t('txt.weWillProp')"></span>
          <span v-html="$t(`services.${modal.key}.F`)"></span>
          <span class="small">
            <span v-html="$t('txt.releaseOn')"></span>
            <span v-html="$t(`services.${modal.key}.FDate`)"></span>
            <span v-html="$t('txt.withHelp')"></span>
            <a
              class="btn btn-sm btn-soutenir py-0 px-1"
              :href="$t('link.S')"
              :title="$t('meta.support')"
            >
              <i class="fas fa-heart fa-fw"></i>
              <span
                class="sr-only"
                v-html="$t('meta.support')"
              ></span>
            </a>
          </span>
        </p>
        <p
          v-else
          class="m-0"
        >
          <span v-html="$t('txt.wePropNow')"></span>
          <span v-html="$t(`services.${modal.key}.F`)"></span>
          <span
            class="small"
            v-html="`${$t('txt.since')} ${$t(`services.${modal.key}.FDate`)}`"
          ></span>
        </p>
      </div>

      <web-screen
        :img="`${$t('baseurl')}img/screens/${
          $t(`services.${modal.key}.F`, '-tL@~')
        }-full.png`"
      />

      <!-- desc -->
      <p
        v-html="$t(`services.${modal.key}.mBody.desc`)
          .replace(/@framaservice/g, $t(`services.${modal.key}.F`))"
      ></p>

      <!-- video / desc -->
      <p
        v-if="$te(`services.${modal.key}.mBody.more`)"
        v-html="$t(`services.${modal.key}.mBody.more`)
          .replace(/@framaservice/g, $t(`services.${modal.key}.F`))"
      ></p>

      <!-- features -->
      <div v-if="$te(`services.${modal.key}.mBody.feat`)">
        <h5
          class="h3 violet"
          v-text="$t('txt.features')"
        ></h5>
        <ul v-if="Array.isArray($t(`services.${modal.key}.mBody.feat`))">
          <li
            v-for="item in $t(`services.${modal.key}.mBody.feat`)"
            :key="item"
            v-html="item.replace(/@framaservice/g, $t(`services.${modal.key}.F`))"
          ></li>
        </ul>
        <p
          v-else
          v-html="$t(`services.${modal.key}.mBody.feat`)
            .replace(/@framaservice/g, $t(`services.${modal.key}.F`))"
        ></p>
      </div>

      <!-- modal-footer -->
      <template #modal-footer="{}">
        <!-- source / framacloud -->
        <p
          v-if="!$te(`services.${modal.key}.mFooter`)"
          class="small text-left m-3"
        >
          <span v-html="$t(`services.${modal.key}.F`)"></span>
          <span v-html="$t('txt.basedOn')"></span>
          <span v-html="$t(`services.${modal.key}.S`)"></span>
          <span v-if="$te(`services.${modal.key}.CL`) && $t('lang') === 'fr'">
            <br />
            <i class="fas fa-leaf fc-g6"></i>
            <span v-html=" $t('txt.howTo')"></span>
            <a
              class="text-success text-underline"
              :href="$t(`services.${modal.key}.CL`)"
              v-html="$t('txt.installOnServer')"
            ></a>
          </span>
        </p>
        <!-- alt / docs -->
        <b-row>
          <div class="col-md-6 text-left">
            <a
              class="btn btn-outline-secondary"
              :href="`#${modal.key}`"
              @click="modal.open = false;"
              v-html="$t('txt.otherAlt')"
            ></a>
            <a
              class="btn btn-outline-secondary"
              :href="docLink(modal.key)"
              v-html="$t('txt.docs')"
            ></a>
          </div>
          <!-- use -->
          <div class="col-md-6 text-right">
            <a
              class="btn btn-lg btn-link text-uppercase"
              :href="$t(`services.${modal.key}.FL`)"
              v-html="$t('txt.use')"
            ></a>
          </div>
        </b-row>
      </template>
    </b-modal>

    <b-modal
      v-if="modal.key && modal.key === 'gmail'"
      id="FramaModalGmail"
      v-model="modal.open"
      :class="`m-${modal.key}`"
      ok-only
      :ok-title="$t('txt.close')"
      ok-variant="light"
    >
      <div slot="title">
        <h1>
          <span
            class="desc"
            v-html="$t(`services.${modal.key}.lDesc`)"
          ></span>
        </h1>
      </div>
      <p
        class="alert alert-warning"
        v-html="$t(`services.${modal.key}.mBody`)
          .replace('@gafamservices', $t(`services.${modal.key}.gafam`).join(', '))"
      ></p>
      <div slot="footer">
        <a
          class="btn btn-alt btn-default"
          :href="'#' + modal.key"
          @click="modal.open = false;"
          v-text="$t('txt.otherAlt')"
        ></a>
      </div>
    </b-modal>

    <b-modal
      id="CampsModal"
      v-model="modal2.open"
      ok-only
      :ok-title="$t('txt.close')"
      ok-variant="light"
    >
      <div slot="title">
        <h1
          id="CampsLabel"
          class="text-center"
          v-html="$t(`camps.${modal2.key}.title`)"
        ></h1>
      </div>
      <p v-html="$t(`camps.${modal2.key}.text1`)"></p>
      <p v-html="$t(`camps.${modal2.key}.text2`)"></p>
      <p class="text-center">
        <img
          alt=""
          :src="`${$t('baseurl')}img/${$t('lang')}/${$t(`camps.${modal2.key}.img`)}.png`"
        />
      </p>
      <div slot="footer">
        <div class="text-right">
          <a
            v-if="$te(`camps.${modal2.key}.more`)"
            class="btn btn-lg btn-link text-uppercase"
            :href="`${$t('baseurl')}${$t('lang')}/#enjeux`"
            v-text="$t('txt.more')"
          ></a>
          <a
            class="btn btn-lg btn-link text-uppercase"
            href="javascript:void(0);"
            @click="modal2.open = false;"
            v-text="$t('txt.close')"
          ></a>
        </div>
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import vSelect from 'vue-select';
import WebScreen from '../../../commons/components/WebScreen.vue';

export default {
  components: {
    vSelect,
    WebScreen,
  },

  props: {
    img: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      modal: {
        open: false,
        key: 'bitly',
      },
      modal2: {
        open: false,
        key: 'village',
      },
      play: true,
      results: '',
      mapHighlight: {
        village: {
          strokeColor: '6A5687',
          strokeWidth: 3,
          fillColor: 'ffffff',
          fillOpacity: 0.2,
        },
        camps: {
          strokeColor: '0C5B7A',
          strokeWidth: 3,
          fillColor: 'ffffff',
          fillOpacity: 0.2,
        },
        services: {
          strokeColor: '677835',
          strokeWidth: 2,
          fillColor: 'ffffff',
          fillOpacity: 0.2,
        },
      },
    };
  },

  mounted() {
    /* eslint-disable */
    if (!document.getElementById('app').hasAttribute('data-server-rendered')) {
      window.jQuery = require('jquery');
      require('maphilight/jquery.maphilight.min.js');
      require('image-map-resizer/js/imageMapResizer.min.js');

      jQuery(document).ready(() => {
        jQuery('map').imageMapResize();
        jQuery('#carte').maphilight();
      });
    }
    /* eslint-enable */
  },

  methods: {
    tags(services) {
      const tags = [];
      Object.keys(services).forEach((k) => {
        if (Array.isArray(services[k].gafam) && services[k].pos) {
          const main = services[k].gafam[0];
          const others = services[k].gafam
            .join(', ')
            .replace(`${main}`, '')
            .replace(/^, ?/, '')
            .replace(/^/, '(')
            .replace(/$/, ')')
            .replace('()', '');
          tags.push({
            key: k,
            gafam: `${main} ${others}`,
          });
        }
      });
      return tags;
    },

    docLink(key) {
      const service = this.$t(`services.${key}.id`) || null;
      const path = this.$t(`doc.${service}`) || false;
      let link = this.$t('link.docs');
      if (path) {
        if (this.$t('lang') !== 'fr'
          && path[1] !== undefined) {
          link += `/${path[1]}`; // english doc by default
        } else {
          link += `/${path[0]}`; // fallback on french if missing
        }
        link += '/index.html';
      }
      return link;
    },
  },
};
</script>

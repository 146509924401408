<template>
  <main>
    <b-container class="ombre">
      <header-component />
      <alt-map :img="img" />
    </b-container>

    <!-- Listes des alternatives -->
    <b-container class="ombre px-3">
      <alt-menu />

      <div
        v-for="(icon, cat) in $t('cat1.icons')"
        :key="cat"
      >
        <a
          :id="cat"
          class="anchor"
          rel="nofollow"
        ></a>
        <b-card
          bg-variant="light"
          class="my-4"
          no-body
        >
          <h2 class="text-center mt-2 mb-0">
            <i :class="`${icon} fa-fw fa-xs my-2 ml-3 fc-g6 float-left`"></i>
            <span v-html="$t(`cat1.${cat}`)"></span>
          </h2>
          <table class="table table-hover table-borderless border-0 m-0">
            <thead class="text-center">
              <tr>
                <th
                  class="p-2"
                  scope="col"
                  v-html="$t('alt.table.th1')"
                ></th>
                <th
                  class="p-2"
                  scope="col"
                  v-html="`${$t('meta.fname')}&nbsp;${$t('alt.table.th2')}`"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="key in Object.keys($t('services'))
                  .filter(key => $t(`services.${key}.c1`) === cat)"
                :key="key"
                :class="`alt-${key}`"
              >
                <td>
                  <a
                    :id="key"
                    class="anchor"
                    rel="nofollow"
                  ></a>
                  <h3 class="h5 my-2">
                    <a
                      class="fc-g7"
                      :href="`#${key}`"
                      rel="nofollow"
                      v-html="$t(`services.${key}.sDesc`)"
                    ></a>
                  </h3>
                  <ul class="list-group">
                    <li
                      v-for="gafam in $t(`services.${key}.gafam`)"
                      :key="gafam"
                      class="list-group-item"
                    >
                      <img
                        v-if="Object.keys(img.gafam).indexOf($t(gafam, '-ktL@')) > -1"
                        alt=""
                        :src="img.gafam[$t(gafam, '-ktL@')]"
                      />
                      <span v-text="gafam.replace(/@:[.a-z]+ /g, '')"></span>
                    </li>
                  </ul>
                </td>
                <td>
                  <ul class="list-group mb-3">
                    <li
                      v-if="$te(`services.${key}.F`) || $te(`services.${key}.chatons`)"
                      class="list-group-item"
                    >
                      <a
                        v-if="$te(`services.${key}.F`)"
                        :href="$t(`services.${key}.FL`)"
                      >
                        <img
                          v-if="Object.keys(img.leds)
                            .indexOf($t(`services.${key}.F`, '-tL@')) > -1"
                          alt=""
                          :src="img.leds[$t(`services.${key}.F`, '-tL@')]"
                        />
                        <span v-html="$t(`services.${key}.F`)"></span>
                      </a>
                      <span v-if="$te(`services.${key}.F`) && $te(`services.${key}.chatons`)">
                        /
                      </span>
                      <a
                        v-if="$te(`services.${key}.chatons`)"
                        :href="$t(`services.${key}.chatons`)"
                      >
                        <img
                          alt=""
                          :src="img.leds.chatons"
                        />
                        <b v-html="$t('txt.chatons')"></b>
                      </a>
                      <span
                        v-if="cat !== 'home'"
                        :title="$t('txt.altOnline')"
                        class="float-right fc-g6"
                      >
                        <i class="fas fa-cloud"></i>
                      </span>
                    </li>
                    <li
                      v-if="$te(`services.${key}.S`)"
                      class="list-group-item"
                    >
                      <img
                        v-if="Object.keys(img.leds)
                          .indexOf($t(`services.${key}.S`, '-tL@')) > -1"
                        alt=""
                        :src="img.leds[$t(`services.${key}.S`, '-tL@')]"
                      />
                      <span v-html="$t(`services.${key}.S`)"></span>
                      <span
                        v-if="cat !== 'home'"
                        :title="$t('txt.altOffline')"
                        class="float-right fc-g6"
                      >
                        <i class="fas fa-server"></i>
                      </span>
                    </li>
                  </ul>
                  <ul class="list-group">
                    <li
                      v-for="alt in ($te(`services.${key}.altOn`)
                        ? $t(`services.${key}.altOn`) : '')"
                      :key="alt"
                      class="list-group-item"
                    >
                      <img
                        v-if="Object.keys(img.leds).indexOf($t(alt, '-ktL@')) > -1"
                        :src="img.leds[$t(alt, '-ktL@')]"
                        alt=""
                      />
                      <span v-html="alt"></span>
                      <span
                        v-if="cat !== 'home'"
                        :title="$t('txt.altOnline')"
                        class="float-right fc-g6"
                      >
                        <i class="fas fa-cloud"></i>
                      </span>
                    </li>
                    <li
                      v-for="alt in ($te(`services.${key}.altOff`)
                        ? $t(`services.${key}.altOff`) : '')"
                      :key="alt"
                      class="list-group-item"
                    >
                      <img
                        v-if="Object.keys(img.leds).indexOf($t(alt, '-ktL@')) > -1"
                        alt=""
                        :src="img.leds[$t(alt, '-ktL@')]"
                      />
                      <span v-html="alt"></span>
                      <span
                        v-if="cat !== 'home'"
                        :title="$t('txt.altOffline')"
                        class="float-right fc-g6"
                      >
                        <i class="fas fa-server"></i>
                      </span>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </div>

      <Signature />
    </b-container>

    <b-modal
      id="modal-alt"
      :title="$t('modal.title')"
      :header-close-label="$t('txt.close')"
      :cancel-title-html="$t('txt.close')"
      cancel-variant=" d-none"
    >
      <div v-html="$t('modal.alt')"></div>
    </b-modal>
  </main>
</template>

<script>
import HeaderComponent from '../components/Header.vue';
import Signature from '../components/Signature.vue';

import AltMap from './alternatives/AltMap.vue';
import AltMenu from './alternatives/AltMenu.vue';

import img from '../../public/img/img.json';

export default {
  name: 'Alt',

  components: {
    HeaderComponent,
    Signature,
    AltMap,
    AltMenu,
  },

  data() {
    return {
      img: !document.getElementById('app').hasAttribute('data-server-rendered')
        ? img
        : { gafam: {}, leds: {} },
    };
  },

  mounted() {
    this.$bvModal.show('modal-alt');
  },
};
</script>

<template>
  <section class="landing">
    <b-container fluid>
      <b-container class="px-0">
        <div class="text-right">
          <I18n />
        </div>
        <h1
          v-html="$t('color.dio')"
        ></h1>
      </b-container>
    </b-container>
  </section>
</template>

<script>
import I18n from '../../../commons/components/I18n.vue';

export default {
  components: {
    I18n,
  },
};
</script>

<style lang="scss">
#home .landing {
  background-image: url('../../../public/img/Lets-leave-planet-GAFAM-NATU-BATX_by-David-Revoy.jpg');
  background-size: cover;
  background-position: center;
  z-index: 1;

  h1 {
    font-family: 'Tovari Sans', sans-serif;
    font-weight: bold;
    margin: 1rem auto 0 2.5%;
    max-width: 580px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    text-shadow: none;

    b {
      color: #fff;
      display: inline-block;
      border-radius: .5rem;
    }

    .violet { /* = background orange */
      background: var(--f-o6);
      font-size: 4.5rem;
      line-height: 3.75rem;
      padding: 1.25rem 1.75rem 0;
      transform: rotate(-2deg);
    }

    .orange { /* = background violet */
      background: var(--f-o6);
      font-size: 3.5rem;
      line-height: 2.75rem;
      padding: .75rem 1.5rem 0;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: rotate(-5deg);
    }

    a {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width:768px) {
    h1{
      font-size: 2.5rem;
    }
  }

  @media(max-width:576px) {
    h1 {
      font-size:calc(2.5vw * 8 / 3);
      padding-bottom: calc(3vw * 8 / 3);

      .violet {
        font-size:calc(4.5vw * 8 / 3);
        line-height: calc(3.75vw * 8 / 3);
        padding: calc(1.25vw * 8 / 3) calc(1.75vw * 8 / 3) 0;
      }

      .orange {
        font-size:calc(3.5vw * 8 / 3);
        line-height: calc(2.75vw * 8 / 3);
        padding: calc(.75vw * 8 / 3) calc(1.5vw * 8 / 3) 0;
      }
    }
  }

  .container:not(.ombre) {
    padding: 20px 30px;
    min-height: 800px;
  }

  .dropdown {
    button {
      border: 1px solid var(--f-g3);
      i::before {
        color: var(--f-g2);
      }
      color: var(--f-g2);

      &:hover {
        border-color: transparent;
        color: var(--f-d1);
      }
    }

    &.show button{
      border-color: transparent;
      color: var(--f-d1);
    }
  }
}
</style>

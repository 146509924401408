<template>
  <section
    id="solutions"
    class="row"
  >
    <b-container class="ombre">
      <b-row>
        <b-col
          lg="8"
          xl="9"
        >
          <h2>
            <a id="leds"></a>
            <span v-html="$t('home.explain.props.title')"></span>
          </h2>
          <p v-html="$t('home.explain.props.text')"></p>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          lg="6"
          class="mt-4"
        >
          <h3>
            <i class="fas fa-lock-open fa-fw fc-g6"></i>
            <span v-html="$t('home.explain.props.libre.title')"></span>
          </h3>
          <p v-html="$t('home.explain.props.libre.text1')"></p>
          <p v-html="$t('home.explain.props.libre.text2')"></p>
          <p>
            <strong v-html="$t('home.explain.props.libre.text3')"></strong>
          </p>
        </b-col>
        <b-col
          lg="6"
          class="mt-4"
        >
          <h3>
            <i class="far fa-eye-slash fa-fw fc-g6"></i>
            <span v-html="$t('home.explain.props.ethic.title')"></span>
          </h3>
          <p v-html="$t('home.explain.props.ethic.text1')"></p>
          <p v-html="$t('home.explain.props.ethic.text2')"></p>
          <p>
            <strong v-html="$t('home.explain.props.ethic.text3')"></strong>
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          lg="6"
          class="mt-4"
        >
          <h3>
            <i class="fas fa-share-alt fa-fw fc-g6"></i>
            <span v-html="$t('home.explain.props.decent.title')"></span>
          </h3>
          <p v-html="$t('home.explain.props.decent.text1')"></p>
          <p v-html="$t('home.explain.props.decent.text2')"></p>
          <p>
            <strong v-html="$t('home.explain.props.decent.text3')"></strong>
          </p>
        </b-col>
        <b-col
          lg="6"
          class="mt-4"
        >
          <h3>
            <i class="fas fa-people-carry fa-fw fc-g6"></i>
            <span v-html="$t('home.explain.props.solid.title')"></span>
          </h3>
          <p v-html="$t('home.explain.props.solid.text1')"></p>
          <p v-html="$t('home.explain.props.solid.text2')"></p>
          <p>
            <strong v-html="$t('home.explain.props.solid.text3')"></strong>
          </p>
        </b-col>
      </b-row>

      <img
        alt=""
        class="d-block mt-5"
        :src="`${$t('baseurl')}img/${$t('lang')}/${$t('img.gee[4]')}`"
      />

      <b-row>
        <b-col
          class="my-5 mx-auto"
          lg="8"
          xl="9"
        >
          <h2 v-html="$t('home.explain.concrete.title')"></h2>
          <p v-html="$t('home.explain.concrete.text1')"></p>
          <p v-html="$t('home.explain.concrete.text2')"></p>
          <p v-html="$t('home.explain.concrete.text3')"></p>
          <p v-html="$t('home.explain.concrete.text4')"></p>
          <p v-html="$t('home.explain.concrete.text5')"></p>
          <p class="text-center">
            <router-link
              class="btn btn-warning btn-lg"
              :to="`/${$t('lang')}/list`"
              v-html="$t('list.title')"
            />
          </p>
        </b-col>
      </b-row>

      <img
        alt=""
        class="d-block"
        :src="`${$t('baseurl')}img/cloud.jpg`"
      />

      <Signature />
    </b-container>
  </section>
</template>

<script>
import Signature from '../../components/Signature.vue';

export default {
  components: {
    Signature,
  },
};
</script>

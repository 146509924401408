<template>
  <section
    id="dio-why"
    class="row"
  >
    <b-container class="ombre">
      <b-row class="m-0 align-items-center">
        <b-col
          id="problems"
          lg="5"
          v-html="$t('home.why.problems', { icon: icon.problems })"
        />

        <b-col
          id="conf"
          lg="7"
          class="p-0 pl-lg-5"
        >
          <a
            v-if="!play"
            :href="/^fr/.test($t('lang'))
              ? 'https://framatube.org/w/seQRTkJ9jYNRJP24vgiB31'
              : 'https://framatube.org/w/74Uqr9svMNYqu54DNkAkRw'"
            @click.prevent="play = true"
          >
            <figure>
              <img
                :src="`${$t('baseurl')}img/video-conf.jpg`"
                alt=""
                class="figure-img img-fluid"
              />
              <figcaption
                class="fc-g8 text-center"
                v-html="$t('home.why.conf')"
              ></figcaption>
            </figure>
          </a>
          <figure v-else>
            <div
              class="embed-responsive embed-responsive-16by9 mb-2"
            >
              <iframe
                allowfullscreen=""
                class="embed-responsive-item"
                sandbox="allow-same-origin allow-scripts allow-popups"
                :src="/^fr/.test($t('lang'))
                  ? 'https://framatube.org/videos/embed/d47c62a1-06aa-477a-8884-73ddc416a458?autoplay=1&amp;title=0&amp;warningTitle=0'
                  : 'https://framatube.org/videos/embed/31225e78-5f41-41dc-bfca-5e63b34e7be4?autoplay=1&amp;title=0&amp;warningTitle=0'"
                :title="$t('home.why.conf')"
              ></iframe>
            </div>
            <figcaption
              class="fc-g8 text-center"
              v-html="$t('home.why.conf')"
            ></figcaption>
          </figure>
        </b-col>

        <b-col
          id="mooc"
          lg="7"
          class="p-0 pr-lg-5"
        >
          <a href="https://mooc.chatons.org/course/view.php?id=3">
            <figure>
              <img
                :src="`${$t('baseurl')}img/mooc-chatons.jpg`"
                alt=""
                class="figure-img img-fluid"
              />
              <figcaption
                class="text-center fc-g8"
                v-html="$t('home.why.mooc')"
              ></figcaption>
            </figure>
          </a>
        </b-col>

        <b-col
          id="solutions"
          lg="5"
          class="mt-5"
          v-html="$t('home.why.solutions', { icon: icon.solutions })"
        />
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      icon: {
        problems: '<i class="fas fa-triangle-exclamation"></i>',
        solutions: '<i class="fas fa-circle-check"></i>',
      },
      play: false,
    };
  },
};
</script>

<style lang="scss">
#dio-why {
  margin-top: -7rem;
  padding-bottom: 3rem;

  .container.ombre {
    background: transparent;
    box-shadow: none;
    padding: 0;
  }

  .row > div {
    background: var(--f-g2);
    border-radius: 1.75rem;
    padding: 1.75rem;
    margin: 1rem 0;
  }

  h2 {
    margin: -3rem auto 1.5rem;
    text-align: center;
    transform: rotate(-2deg);

    em {
      display: inline-block;
      background: var(--f-o6);
      padding: .5rem 1rem 0;
      border-radius: .5rem;
      color: #fff;
      font-size: 1.75rem;
      font-family: 'Tovari Sans', sans-serif;
      text-transform: uppercase;
      line-height:2rem;
      font-style: normal;
    }
  }

  #conf, #mooc {
    background: transparent;

    a {
      text-decoration: none;
    }
  }
  figure {
    background: var(--f-f7);
    border-radius: .5rem;
    padding: .5rem;
    margin: 0;
  }

  figcaption {
    color: #fff;
  }

  a:hover figure {
    background: var(--f-o6);
  }
}

#problems, #solutions {
  ul {
    list-style: none;
    padding-left: 1.75rem;
    margin: 0;

    .fas {
      margin-left: -1.5rem;
      margin-right: .25rem;
      color: var(--f-i9);
    }

    li {
      padding: .25rem 0;
    }
  }

  p:last-of-type {
    margin: 0;
  }
}
#dio-why #solutions {
  margin-bottom: 0;
}
</style>

<template>
  <section class="row landing">
    <b-container fluid>
      <b-container>
        <div class="float-right">
          <I18n />
        </div>
        <h1 v-html="$t('meta.DIo')"></h1>
        <b-row
          id="bubbles"
          align-h="between"
          class="hidden-xs text-center mt-5"
        >
          <b-col
            class="bbl"
            md="4"
          >
            <p class="p-3 mt-5 mr-auto ml-0 d-flex">
              <a
                href="#enjeux"
                class="align-self-center"
                @mouseover="$parent.explain=true;"
                @click="$parent.explain=true;"
                v-html="$t('home.intro.bubble1')"
              ></a>
            </p>
          </b-col>
          <b-col
            class="bbl"
            md="4"
          >
            <p class="p-3 d-flex">
              <a
                href="#solutions"
                class="align-self-center"
                @mouseover="$parent.explain=true;"
                @click="$parent.explain=true;"
                v-html="$t('home.intro.bubble2')"
              ></a>
            </p>
          </b-col>
          <b-col
            class="bbl"
            md="4"
          >
            <p class="p-3 mt-5 ml-auto mr-0 d-flex">
              <router-link
                class="align-self-center"
                :to="`/${$t('lang')}/list`"
                v-html="$t('home.intro.bubble3')"
              />
            </p>
          </b-col>
        </b-row>
      </b-container>

      <b-container class="ombre choice">
        <b-row class="text-center m-0">
          <b-col
            lg="6"
            class="fb-f7 h3 fc-light m-0 p-3"
          >
            <router-link
              :to="`/${$t('lang')}/list`"
              class="text-decoration-none d-flex flex-column justify-content-between h-100"
            >
              <p class="fc-light">
                <span v-html="$t('home.intro.btnList1')"></span>
                <br />
                <small>
                  <i
                    v-html="$t('home.intro.btnList2')"
                  ></i>
                </small>
              </p>
              <p
                class="btn-lg btn-light"
                v-html="$t('home.intro.btnList3')"
              ></p>
            </router-link>
          </b-col>
          <b-col
            lg="6"
            class="fb-o6 h3 m-0 p-3"
          >
            <router-link
              :to="`/${$t('lang')}/alternatives`"
              class="text-decoration-none d-flex flex-column justify-content-between h-100"
            >
              <p class="fc-light">
                <span v-html="$t('home.intro.btnAlt1')"></span>
                <br />
                <small>
                  <i
                    class="fc-light"
                    v-html="$t('home.intro.btnAlt2')"
                  ></i>
                </small>
              </p>
              <p
                class="btn-lg btn-light"
                v-html="$t('home.intro.btnAlt3')"
              ></p>
            </router-link>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </section>
</template>

<script>
import I18n from '../../../commons/components/I18n.vue';

export default {
  components: {
    I18n,
  },
};
</script>

<style lang="scss">
.landing {
  background-image: url('../../../public/img/Peha-Banquet-CC-By-1920.jpg');
  background-size: cover;
  background-position: center;

  h1{
    font-size: 69px;
    text-align: center;
    text-shadow: 0px 0px 10px #000;
    margin-top: 1rem;
    padding-bottom: 3rem;

    b {
      color: #fff;
    }
  }

  @media (max-width:768px) {
    h1{
      font-size: 36px;
    }
  }

  .container:not(.ombre) {
    padding: 50px;
    min-height: 700px;
  }

  #bubbles {
    min-height: 457px;
    p {
      background: var(--f-v6);
      font-size: 18px;
      line-height: 22px;
      border: none;
      color: #fff;
      border-radius: 50%;
      width: 200px;
      height: 200px;
      margin: 0 auto;
      box-shadow: 0px 2px 2px #000;
      visibility: hidden;
      opacity: 0.9;

      a {
      border: none;
      color: #fff;
      background: none;
      }

      &:hover {
        opacity: 1;
      }
    }

    .bbl:first-of-type p {
      background: var(--f-r8);
      animation: 1s fadeIn;
      animation-fill-mode: forwards;
    }

    .bbl:nth-of-type(2) p {
      animation: 2s fadeIn;
      animation-fill-mode: forwards;
    }

    .bbl:last-of-type p {
      background: var(--f-f7);
      animation: 3s fadeIn;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.choice {
  padding: 0 !important;
}
</style>

<template>
  <b-col
    v-if="category === $t(`dio.${id}.category`)"
    :id="`s-${id}`"
    class="mb-4"
    md="6"
    tag="article"
  >
    <b-card
      class="h-100"
      no-body
    >
      <b-row
        class="h-100"
        no-gutters
      >
        <b-col
          lg="5"
          class="d-flex align-items-center"
        >
          <a
            aria-hidden="true"
            :href="$t(`link.${id}`)"
            rel="nofollow"
          >
            <b-card-img
              alt=""
              :src="`${$t('baseurl')}img/screens/${id}.png`"
            />
          </a>
        </b-col>

        <b-col lg="7">
          <b-card-body class="h-100 d-flex flex-column">
            <h3 class="card-title h4">
              <a
                :href="$t(`link.${id}`)"
                rel="nofollow"
              >
                <i :class="`${$t(`icon.${id}`)} fa-lg fc-g6 fa-pull-right`"></i>
                <div class="d-flex align-items-center text-left">
                  <span>
                    <span v-html="$t(`color.${id}`)"></span>
                    <br />
                    <small><s
                      class="badge badge-light"
                      v-html="$t(`dio.${id}.gafam[0]`)"
                    ></s></small>
                  </span>
                </div>

              </a>
            </h3>
            <b-card-text>
              <div v-html="$t(`dio.${id}.lDesc`)"></div>
            </b-card-text>
            <b-card-text class="mt-auto text-right">
              <a
                class="btn btn-sm btn-outline-warning d-inline-flex align-items-center"
                :href="$t(`link.${id}`)"
              >

                <span v-html="$t('txt.use')"></span>
                <span
                  class="not-sr-only mx-1"
                  v-html="$t(`txt.${id}`)"
                ></span>
                <i class="fas fa-chevron-right"></i>
              </a>
            </b-card-text>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>

<script>

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <main>
    <b-container class="ombre">
      <header-component />

      <b-row>
        <b-col
          lg="8"
          xl="9"
          class="m-0"
        >
          <h2 v-html="$t('medias.title')"></h2>
          <p v-html="$t('medias.intro')"></p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          lg="10"
          xl="8"
          class="mx-auto mb-5"
        >
          <b-embed
            aspect="16by9"
            :src="`${$t('baseurl')}${$t('lang')}/timeline`"
            :title="$t('medias.timeline')"
            type="iframe"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          lg="8"
          xl="9"
          class="mx-0"
        >
          <!-- Visuels-->
          <h2 v-html="$t('medias.tabs.title')"></h2>
          <p v-html="$t('medias.tabs.intro')"></p>
        </b-col>
      </b-row>
      <b-row class="mx-0">
        <b-tabs
          active-tab-class="fb-g2 p-2"
          class="w-100"
          nav-wrapper-class="fb-g3 p-2"
          pills
        >
          <!-- Cartes -->
          <b-tab id="cartes">
            <template v-slot:title>
              <span v-html="$t('medias.tabs.tab1.title')"></span>
            </template>
            <p v-html="$t('medias.tabs.tab1.intro')"></p>

            <b-row>
              <b-col
                v-for="key in Object.keys($t('medias.tabs.tab1.img'))"
                :key="key"
                class="my-2"
                lg="4"
              >
                <div class="img-thumbnail text-center">
                  <h3 v-html="$t(`medias.tabs.tab1.img.${key}[0]`)"></h3>
                  <b-img
                    alt=""
                    fluid
                    :src="`${$t('baseurl')}img/${$t('lang')}/${$t(`img.carte.${key}`)}`"
                  />
                  <p
                    class="text-center"
                    v-html="$t(`medias.tabs.tab1.img.${key}[1]`)"
                  ></p>
                </div>
              </b-col>
            </b-row>
          </b-tab>

          <!-- Dessins Gee -->
          <b-tab id="dessins">
            <template v-slot:title>
              <span v-html="$t('medias.tabs.tab2.title')"></span>
            </template>
            <p v-html="$t('medias.tabs.tab2.intro')"></p>

            <b-row>
              <b-col
                v-for="index in Object.keys($t('medias.tabs.tab2.img'))"
                :key="index"
                class="my-2"
                md="4"
              >
                <div class="img-thumbnail text-center">
                  <h3 v-html="$t(`medias.tabs.tab2.img[${index}]`)"></h3>
                  <b-img
                    alt=""
                    fluid
                    :src="`${$t('baseurl')}img/${$t('lang')}/${$t(`img.gee[${index}]`)}`"
                  />
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                class="mx-auto mt-4"
                md="8"
              >
                <div class="img-thumbnail text-center">
                  <h3 v-html="$t('meta.dio')"></h3>
                  <video
                    controls="controls"
                    width="540"
                    height="360"
                  >
                    <source
                      :src="`${$t('baseurl')}img/${$t('lang')}/anim_dio.webm`"
                      type="video/webm"
                    />
                    <source
                      :src="`${$t('baseurl')}img/${$t('lang')}/anim_dio.mp4`"
                      type="video/mp4"
                    />
                  </video>
                  <pre
                    class="text-left"
                    v-text="`&lt;video controls=&quot;controls&quot; width=&quot;540&quot; ${
                      ''}height=&quot;360&quot;&gt;&lt;source src=&quot;${$t('link.dio')}/img/${
                      $t('lang')}/anim_dio.webm&quot; type=&quot;video/webm&quot;/&gt;${
                      ''}&lt;source src=&quot;${$t('link.dio')}/img/${
                      $t('lang')}/anim_dio.mp4&quot; type=&quot;video/mp4&quot; /&gt;${
                      ''}&lt;/video&gt;`"
                  ></pre>
                </div>
              </b-col>
            </b-row>
          </b-tab>

          <!-- Dessins Péhä -->
          <b-tab id="dessins-peha">
            <template v-slot:title>
              <span v-html="$t('medias.tabs.tab6.title')"></span>
            </template>
            <p v-html="$t('medias.tabs.tab6.intro')"></p>

            <b-row>
              <b-col
                class="my-2 mx-auto"
                md="8"
              >
                <div class="img-thumbnail text-center">
                  <h3 v-html="$t('medias.tabs.tab6.img[4]')"></h3>
                  <b-img
                    alt=""
                    fluid
                    :src="`${$t('baseurl')}img/${$t('lang')}/${$t('img.peha[4]')}`"
                  />
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                v-for="index in Object.keys($t('medias.tabs.tab6.img')).filter(i => i != 4)"
                :key="index"
                class="my-2"
                md="6"
              >
                <div class="img-thumbnail text-center">
                  <h3 v-html="$t(`medias.tabs.tab6.img[${index}]`)"></h3>
                  <b-img
                    alt=""
                    fluid
                    :src="`${$t('baseurl')}img/${$t('lang')}/${$t(`img.peha[${index}]`)}`"
                  />
                </div>
              </b-col>
            </b-row>
          </b-tab>

          <!-- Stickers -->
          <b-tab id="stickers">
            <template v-slot:title>
              <span v-html="$t('medias.tabs.tab5.title')"></span>
            </template>

            <b-row>
              <b-col
                v-for="(sticker, index) in $t('img.stickers')"
                :key="index"
                class="my-2"
                md="4"
              >
                <div class="img-thumbnail text-center">
                  <h3 v-html="sticker[0]"></h3>
                  <img
                    alt=""
                    class="img-fluid"
                    :src="`${$t('baseurl')}img/${sticker[1]}`"
                  />
                  <p
                    class="text-center"
                    v-html="sticker[2]"
                  ></p>
                </div>
              </b-col>
            </b-row>
          </b-tab>

          <!-- Vidéos -->
          <b-tab id="videos">
            <template v-slot:title>
              <span v-html="$t('medias.tabs.tab3.title')"></span>
            </template>
            <p v-html="$t('medias.tabs.tab3.intro')"></p>

            <b-row
              v-for="key in Object.keys($t('medias.tabs.tab3.video'))"
              :key="key"
            >
              <b-col
                class="my-2 mx-auto"
                lg="8"
              >
                <h3 v-html="$t(`medias.tabs.tab3.video.${key}[0]`)"></h3>
                <p class="text-center">
                  <b-embed
                    aspect="16by9"
                    :src="$t(`medias.tabs.tab3.video.${key}[1]`)"
                    type="iframe"
                    allowfullscreen
                  />
                </p>
                <pre
                  v-text="`&lt;iframe width=&quot;560&quot; height=&quot;315&quot; src=&quot;${
                    $t(`medias.tabs.tab3.video.${key}[1]`)
                  }&quot; allowfullscreen&gt;&lt;/iframe&gt;`"
                ></pre>
              </b-col>
            </b-row>
          </b-tab>

          <!-- Présentation -->
          <b-tab id="presentation">
            <template v-slot:title>
              <span v-html="$t('medias.tabs.tab4.title')"></span>
            </template>

            <b-row>
              <b-col
                class="my-2 mx-auto"
                md="10"
              >
                <div class="text-center">
                  <h3 v-html="$t('medias.tabs.tab4.title')"></h3>
                  <p class="text-center">
                    <b-embed
                      aspect="4by3"
                      frameborder="0"
                      marginwidth="0"
                      marginheight="0"
                      scrolling="no"
                      :src="`${$t('link.dio')}/presentation/`"
                      type="iframe"
                    />
                  </p>
                  <pre
                    v-text="`&lt;iframe src=&quot;${
                      $t('link.dio')}/presentation/&quot; width=&quot;800&quot; ${
                      ''}height=&quot;600&quot; frameborder=&quot;0&quot; ${
                      ''}marginwidth=&quot;0&quot; marginheight=&quot;0&quot; ${
                      ''}scrolling=&quot;no&quot;&gt;&lt;/iframe&gt;`"
                  ></pre>
                </div>
              </b-col>
            </b-row>
          </b-tab>

          <!-- Infographie G Dorne -->
          <b-tab id="infographie">
            <template v-slot:title>
              <span v-html="$t('medias.tabs.tab7.title')"></span>
            </template>
            <p v-html="$t('medias.tabs.tab7.intro')"></p>

            <b-row>
              <b-col
                class="my-2 mx-auto"
                md="10"
              >
                <div class="img-thumbnail text-center">
                  <h3 v-html="$t('medias.tabs.tab7.title')"></h3>
                  <img
                    alt=""
                    class="img-fluid"
                    data-placement="top"
                    data-toggle="tooltip"
                    :title="$t('medias.tabs.saveas')"
                    :src="`${$t('baseurl')}img/${
                      $t('lang')
                    }/infographie_Le-Monde-de-Framasoft_CC-By-SA-Geoffrey-Dorne-1920px-2018.jpg`"
                  />
                  <p
                    class="text-center"
                    v-html="$t('medias.tabs.tab7.img[0]')"
                  ></p>
                </div>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-row>

      <b-row>
        <b-col
          class="mt-5 mx-0"
          lg="8"
          xl="9"
        >
          <!-- Revue de web-->
          <h2 v-html="$t('medias.press.title')"></h2>
          <p v-html="$t('medias.press.intro')"></p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="mb-5 mx-auto"
          lg="8"
          xl="9"
        >
          <table class="table">
            <thead>
              <tr>
                <th v-html="$t('medias.press.th1')"></th>
                <th v-html="$t('medias.press.th2')"></th>
                <th v-html="$t('medias.press.th3')"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in $t('medias.press.list')"
                :key="item[3]"
                :class="!(item[0] === $t('lang')
                  || (item[0] === 'en'
                  && $t('lang') !== 'fr')) ? 'hidden' : ''"
              >
                <td v-html="(new Intl.DateTimeFormat($t('lang')).format(new Date(item[1])))"></td>
                <td v-html="item[2]"></td>
                <td>
                  <a
                    :href="item[3]"
                    :hreflang="item[0]"
                    v-html="$t(item[4])"
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="8">
          <h2 v-html="$t('medias.frama.title')"></h2>
          <ul>
            <li
              v-for="item in $t('medias.frama.list')"
              :key="item"
              v-html="item"
            ></li>
          </ul>
          <p class="text-center">
            <img
              alt=""
              :src="`${$t('baseurl')}img/biglogo.png`"
              style="width:220px; margin:10px 30px"
            />
          </p>
        </b-col>

        <b-col
          class="bg-light rounded p-4"
          lg="4"
        >
          <p v-html="$t('medias.frama.text1')"></p>
          <p v-html="$t('medias.frama.text2')"></p>
          <p v-html="$t('medias.frama.text3')"></p>
        </b-col>
      </b-row>

      <Signature />
    </b-container>
  </main>
</template>
<script>

import HeaderComponent from '../components/Header.vue';
import Signature from '../components/Signature.vue';

export default {
  components: {
    HeaderComponent,
    Signature,
  },
};
</script>

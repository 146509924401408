<template>
  <main>
    <section class="intro">
      <div class="container">
        <h1>
          <img
            alt=""
            class="mb-2 mr-2"
            :src="`${$t('baseurl')}img/logo.png`"
          />
          <span v-html="$t('timeline.title')"></span>
        </h1>
        <hr class="trait" />
      </div>
    </section>

    <section class="timeline">
      <ul>
        <li
          v-for="(event, index) in $t('timeline.taxonomy')"
          :key="index"
          :class="`li-${event[0]}`"
        >
          <div :class="`fond-${event[0]}`">
            <time
              v-text="(new Intl.DateTimeFormat($t('lang'), $t('timeline.format'))
                .format(new Date(event[1])))"
            ></time>
            <p
              v-html="$t(`timeline.events[${index}]`)
                .replace('<a ', '<a target=\'_parent\' ')"
            ></p>
          </div>
        </li>
      </ul>
    </section>
  </main>
</template>

<script>
export default {
  name: 'Timeline',

  mounted() {
    (() => {
      // check if an element is in viewport
      // http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
      function isElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
          rect.top >= 0
          && rect.left >= 0
          && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
          && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      }

      function callbackFunc() {
        const items = document.querySelectorAll('.timeline li:not(.in-view)');
        for (let i = 0; i < items.length; i += 1) {
          if (isElementInViewport(items[i])) {
            items[i].classList.add('in-view');
          }
        }
      }

      // listen for events
      window.addEventListener('load', callbackFunc);
      window.addEventListener('resize', callbackFunc);
      window.addEventListener('scroll', callbackFunc);
    })();
  },
};
</script>

<style lang="scss">
#timeline {
  overflow-x: hidden;
  margin-top: 0;
  background-color: #fff;

  #app {
    color: #fff;
    padding-bottom: 50px;

    main {
      margin-top: 0;
    }

    a {
      color: #fff;
      text-decoration: underline;
      text-decoration-style: solid;
      background: none;
    }
  }

  /* Classes pour utiliser la couleur de titre en arrière plan */
  .fond-frama  { background-color: var(--f-f7) !important; }
  .fond-soft   { background-color: var(--f-o7) !important; }
  .fond-logiciel { background-color: var(--f-b7) !important; }
  .fond-culture  { background-color: var(--f-r8) !important; }
  .fond-services { background-color: var(--f-v6) !important; }
  .fond-vrac   { background-color: var(--f-j7) !important; }

  /* INTRO SECTION
      –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .intro {
    padding: 3rem 0;
  }

  .container hr {
    margin-top: 3rem;
  }

  .container h1 {
    display: table;
    margin-left: auto;
    margin-right: auto;

    b {
      font-family: 'DejaVu Sans', sans-serif;
    }
  }

  /* TIMELINE
     –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .timeline ul {
    background: #fff;
    padding: 0 0;
  }

  .timeline ul li {
    list-style-type: none;
    position: relative;
    width: 6px;
    margin: 0 auto;
    padding-top: 50px;
    background: #616161;
  }

  .timeline ul li::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: inherit;
  }

  .timeline ul li div {
    position: relative;
    bottom: 0;
    width: 400px;
    padding: 15px;
    background:var(--f-g7);
  }

  .timeline ul li div::before {
    content: '';
    position: absolute;
    bottom: 7px;
    width: 0;
    height: 0;
    border-style: solid;
  }

  .timeline ul li:nth-child(odd) div {
    left: 45px;
  }

  .timeline ul li:nth-child(odd) div::before {
    left: -15px;
    border-width: 8px 16px 8px 0;
    border-color: transparentvar(--f-g7) transparent transparent;
  }

  .timeline ul li:nth-child(odd) div.fond-frama::before {
    border-color: transparent var(--f-f7) transparent transparent;
  }
  .timeline ul li:nth-child(odd) div.fond-soft::before {
    border-color: transparent var(--f-o7) transparent transparent;
  }
  .timeline ul li:nth-child(odd) div.fond-logiciel::before {
    border-color: transparent var(--f-b7) transparent transparent;
  }
  .timeline ul li:nth-child(odd) div.fond-culture::before {
    border-color: transparent var(--f-r8) transparent transparent;
  }
  .timeline ul li:nth-child(odd) div.fond-services::before {
    border-color: transparent var(--f-v6) transparent transparent;
  }
  .timeline ul li:nth-child(odd) div.fond-vrac::before {
    border-color: transparent var(--f-j7) transparent transparent;
  }
  .timeline ul li:nth-child(even) div {
    left: -439px;
  }

  .timeline ul li:nth-child(even) div::before {
    right: -15px;
    border-width: 8px 0 8px 16px;
    border-color: transparent transparent transparent var(--f-g7);
  }
  .timeline ul li:nth-child(even) div.fond-frama::before {
    border-color: transparent transparent transparent var(--f-f7);
  }
  .timeline ul li:nth-child(even) div.fond-soft::before {
    border-color: transparent transparent transparent var(--f-o7);
  }
  .timeline ul li:nth-child(even) div.fond-logiciel::before {
    border-color: transparent transparent transparent var(--f-b7);
  }
  .timeline ul li:nth-child(even) div.fond-culture::before {
    border-color: transparent transparent transparent var(--f-r8);
  }
  .timeline ul li:nth-child(even) div.fond-services::before {
    border-color: transparent transparent transparent var(--f-v6);
  }
  .timeline ul li:nth-child(even) div.fond-vrac::before {
    border-color: transparent transparent transparent var(--f-j7);
  }

  time {
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 8px;
  }

  /* EFFECTS
     –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .timeline ul li::after {
    transition: background .5s ease-in-out;
  }

  .timeline ul li.in-view::after {
    background: var(--f-g7);
  }
  .timeline ul li.in-view.li-frama::after  { background: var(--f-f7); }
  .timeline ul li.in-view.li-soft::after   { background:var(--f-o7); }
  .timeline ul li.in-view.li-logiciel::after { background: var(--f-b7); }
  .timeline ul li.in-view.li-culture::after  { background: var(--f-r8); }
  .timeline ul li.in-view.li-services::after { background: var(--f-v6); }
  .timeline ul li.in-view.li-vrac::after   { background: var(--f-j7); }

  .timeline ul li div {
    visibility: hidden;
    opacity: 0;
    transition: all .5s ease-in-out;
  }

  .timeline ul li:nth-child(odd) div {
    transform: translate3d(200px, 0, 0);
  }

  .timeline ul li:nth-child(even) div {
    transform: translate3d(-200px, 0, 0);
  }

  .timeline ul li.in-view div {
    transform: none;
    visibility: visible;
    opacity: 1;
  }

  /* GENERAL MEDIA QUERIES
     –––––––––––––––––––––––––––––––––––––––––––––––––– */
  @media screen and (max-width: 900px) {
    .timeline ul li div {
      width: 250px;
    }
    .timeline ul li:nth-child(even) div {
      left: -289px;
      /*250+45-6*/
    }
  }

  @media screen and (max-width: 600px) {
    .box, .text {
      display: block;
    }
    .timeline ul li {
      margin-left: 20px;
    }
    .timeline ul li div {
      width: calc(100vw - 91px);
    }
    .timeline ul li:nth-child(even) div {
      left: 45px;
    }
    .timeline ul li:nth-child(even) div::before {
      left: -15px;
      border-width: 8px 16px 8px 0;
      border-color: transparent var(--f-g7) transparent transparent;
    }
  }
}
</style>

<template>
  <main>
    <Landing />

    <More />

    <Speakabout />

    <Enjeux v-show="explain" />

    <Solutions v-show="explain" />
  </main>
</template>

<script>
import Landing from './old/Landing.vue';
import More from './old/More.vue';
import Speakabout from './old/Speakabout.vue';
import Enjeux from './old/Enjeux.vue';
import Solutions from './old/Solutions.vue';

export default {
  components: {
    Landing,
    More,
    Speakabout,
    Enjeux,
    Solutions,
  },

  data() {
    return {
      explain: false,
    };
  },

  mounted() {
    if (/(enjeux|solutions)/.test(this.$t('hash'))) {
      this.explain = true;
      setTimeout(() => {
        document.getElementById(this.$t('hash')).scrollIntoView({ behavior: 'smooth' });
      }, 400);
    }
  },
};
</script>

<style lang="scss">
#enjeux {
  background: var(--f-r8);
}

#solutions {
  background:var(--f-v6);
}

#enjeux,
#solutions {
  .container {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 30px;
  }

  img {
    max-width: 300px;
    margin: auto;
  }
}
</style>

<template>
  <main>
    <b-container class="ombre">
      <header-component />

      <list-menu />

      <a
        id="tagssearch"
        class="anchor"
        rel="nofollow"
      ></a>
      <b-row class="my-4 mx-0">
        <b-col
          sm="6"
          class="mx-auto rounded fb-g2 py-4 px-2"
        >
          <b-row class="my-1 mx-0">
            <b-col
              class="text-right fc-g7 pt-1 px-1"
              cols="1"
              for="tags-select"
              tag="label"
            >
              <i class="fas fa-magnifying-glass fa-lg"></i>
              <span
                class="sr-only"
                v-html="$t('txt.searchByTags')"
              ></span>
            </b-col>
            <b-col
              class="px-1"
              cols="11"
            >
              <v-select
                id="tags-select"
                v-model="results"
                multiple
                :options="tags($t('services')).concat(tags($t('services'), 'gafam'))"
                :placeholder="$t('txt.searchByTags')"
              />
            </b-col>
          </b-row>
          <ul class="list-inline text-center mb-0 mt-3">
            <li
              v-for="tag in $t('tags')"
              :key="tag"
              class="list-inline-item"
            >
              <a
                class="btn btn-sm btn-light border py-0"
                :href="`#tag-${tag}`"
                @click="(results.indexOf(tag) === -1) ? results.push(tag) : results.pop(tag) ;"
                v-text="tag"
              ></a>
            </li>
          </ul>
        </b-col>
      </b-row>

      <b-row
        v-show="results.length > 0"
        id="results"
        ref="results"
        tag="section"
        class="mx-0 fb-o1"
      >
        <b-col cols="12">
          <h2
            class="text-center h1 mb-5"
            v-html="$t('txt.results')"
          ></h2>
        </b-col>

        <list-service
          v-for="key in Object.keys($t('services'))"
          v-show="isInResults([$t(`services.${key}.tags`), $t(`services.${key}.gafam[0]`)]
            .join(), results)"
          :id="key"
          :key="key"
          category="all"
        />
      </b-row>
      <b-row
        v-for="cat in Object.keys($t('cat2.icons'))"
        :key="cat"
        tag="section"
        class="py-4 mx-0"
      >
        <a
          :id="cat"
          rel="nofollow"
          class="anchor"
        ></a>

        <b-col cols="12">
          <h2
            class="text-center h1 mb-5"
            v-html="$t(`cat2.${cat}`)"
          ></h2>
        </b-col>

        <list-service
          v-for="key in Object.keys($t('services'))"
          :id="key"
          :key="key"
          :category="cat"
        />
      </b-row>

      <list-shortcuts />

      <b-modal
        id="modal-list"
        :title="$t('modal.title')"
        :header-close-label="$t('txt.close')"
        :cancel-title-html="$t('txt.close')"
        cancel-variant=" d-none"
      >
        <div v-html="$t('modal.list')"></div>
      </b-modal>

      <Signature />
    </b-container>
  </main>
</template>

<script>
import vSelect from 'vue-select';

import HeaderComponent from '../components/Header.vue';
import Signature from '../components/Signature.vue';

import ListMenu from './list/ListMenu.vue';
import ListService from './list/ListService.vue';
import ListShortcuts from './list/ListShortcuts.vue';

export default {
  name: 'List',

  components: {
    vSelect,
    HeaderComponent,
    Signature,
    ListMenu,
    ListService,
    ListShortcuts,
  },

  data() {
    const [hash] = [window.location.hash];
    const tags = [];
    if (hash && hash.indexOf('#tag-') > -1) {
      tags.push(hash.replace('#tag-', ''));
    }
    return {
      results: tags,
    };
  },

  mounted() {
    this.$bvModal.show('modal-list');
  },

  methods: {
    tags(services, type) {
      let tags = '';
      if (type === 'gafam') {
        Object.keys(services).forEach((k) => {
          if (Array.isArray(services[k].gafam) && this.$t('fight').indexOf(k) > -1) {
            tags += `, ${services[k].gafam[0]}`;
          }
        });
      } else {
        Object.keys(services).forEach((k) => {
          if (typeof services[k].tags === 'string') {
            tags += `, ${services[k].tags}`;
          }
        });
      }
      return tags
        .replace(/^, /, '')
        .split(', ')
        .sort()
        .filter((v, i, a) => a.indexOf(v) === i);
    },

    isInResults(tags, results) {
      let seen = false;
      results.forEach((val) => {
        if (tags.indexOf(val) > -1) {
          seen = true;
        }
      });
      return seen;
    },
  },
};
</script>

<style lang="scss">
#list main {
  section {
    border-bottom: 5px solid var(--f-g2);
  }

  @media (min-width: 992px) {
    section:not(#results) article.col-md-3:nth-of-type(n+5) {
      display: none;
    }
  }

  .dropup li {
    text-align: center;
    font-size: 18px;
  }
  .dropup li a {
    border: none;
    background-image: none;
    padding: 15px;
  }
  .dropup ul.dropdown-menu {
    padding: 0;
    margin-bottom: -46px;
  }

  @media (min-width:768px) {
    .desc {
      min-height: 150px;
      overflow: auto;
      font-size: 16px;
      font-weight: 300;
    }
  }
  @media (min-width:1200px) {
    .desc {
      min-height: 110px;
      overflow: auto;
      font-size: 16px;
      font-weight: 300;
    }
  }
}
</style>
